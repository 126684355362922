import React from 'react';
import './Admin.css';
import { Row , Col , Container } from 'react-bootstrap';


export default function Admin() {
  return(
    <Container fluid>
      <Row>
        <Col>
        </Col>
      </Row>
      <Row>
        <Col>
        </Col>
      </Row>
    </Container>
  )
};