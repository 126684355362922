import React , { useState } from "react";
import { Col, Modal, Row, Button, Form, Alert, ButtonGroup } from "react-bootstrap";
import { Check, PencilSquare, House, Trash, X, GenderFemale, GenderMale } from "react-bootstrap-icons";
import { addCategory , addPotje, deleteCategory, editCategory , deletePotje, editPotje } from "../../../helpers/ApiHandler";

export default function SettingsModal(props) {
    const [alert,setAlert] = useState('');
    const [createCategory,setCreateCategory] = useState(false);
    const [createBudget,setCreateBudget] = useState(false);

    const refresh = props.refresh;

    function transactionRow(transaction,index){
        return(
            <Row key={`transaction-${index}`} className="transaction">
                <Col md={3}>{new Date(transaction.date_booked).toLocaleDateString()}<br />{transaction.account}</Col>
                <Col md={2}><span className={transaction.amount < 0 ? 'neg':'pos'}>{`\u20AC${transaction.amount}`}</span></Col>
                <Col md={6}>{transaction.transaction_description}</Col>
            </Row>
        );
    };
    function CatRow(props){
        const cat = props.category;
        return(
            <Row style={{borderBottom:'1px dashed var(--bs-primary)'}}>
                <Col>{cat.category}</Col>
                <Col>
                    {cat.items.map((item,index) => 
                        <SearchStringRow 
                            item={item} 
                            index={index} 
                            cat={cat}
                            drawBorder={index < cat.items.length -1}
                            key={`category-row-${cat.category}-searchstring-${item.search_string}`} 
                        />
                    )}
                </Col>
            </Row>
        );
    };
    function SearchStringRow(props){
        const [editing,setEditing] = useState(false);
        function deleteSearchTerm(item){
            (async () => {
                const response = await deleteCategory(item.id);
                if(response && response.status === 200) refresh(true);
                else setAlert(`Error: ${response.status} deleting search string`);
            })();
        };
        function editSearchTerm(item){
            (async () => {
                const catObj = {
                    "id":item.id,
                    "search_string":document.getElementById('new-search-string').value,
                    "category":item.category,
                    "account":props.account
                };
                const response = await editCategory(catObj);
                if(response && response.status === 200) {
                    refresh(true)
                    setEditing(false)
                }
                else setAlert(`Error: ${response.status} editing search string`);
            })();
        };
        return(
            <div style={props.drawBorder ? {borderBottom:'1px dashed var(--bs-gray)'}:{}}>
                {editing ? 
                    <>
                        <Form.Control id='new-search-string' size="sm" type="text" defaultValue={props.item.search_string} autoFocus/>
                        <X color="var(--bs-danger)" size={20} className="float-r c-pointer mrgnT1" onClick={() => setEditing(false)}/>
                        <Check color="var(--bs-success)" size={20} className="c-pointer" onClick={() => editSearchTerm(props.item)}/>
                    </>
                :
                    <>
                        {props.item.search_string}
                        <Trash onClick={() => deleteSearchTerm(props.item)} className="float-r mrgnT1 c-pointer" color="var(--bs-danger)" />
                        <PencilSquare onClick={() => setEditing(true)} className='float-r mrgnR2 mrgnT1 c-pointer' />
                    </>
                }
            </div>
        );
    };
    function handleAddCategory(){
        (async () => {
            const catObj = {
                "search_string":document.getElementById('new-search-string').value,
                "category":document.getElementById('new-category').value,
                "account":props.account
            };
            const response = await addCategory(catObj);
            if(response && response.status === 200) {
                refresh(true)
                setCreateCategory(false)
            }
            else setAlert(`Error: ${response.status} adding category`);
        })();
    };
    function handleAddBudget(){
        (async () => {
            const potjeObj = {
                "potje":document.getElementById('new-budget').value,
                "amount":document.getElementById('new-amount').value,
                "account":props.account
            };
            const response = await addPotje(potjeObj);
            if(response && response.status === 200) {
                refresh(true)
                setCreateBudget(false)
            }
            else setAlert(`Error: ${response.status} adding budget`);
        })();
    };
    function BudgetRow(props){
        const [editing,setEditing] = useState(false);
        function handleDeleteBudget(budget){
            (async () => {
                const response = await deletePotje(budget.id);
                if(response && response.status === 200) refresh(true);
                else setAlert(`Error: ${response.status} deleting budget`);
            })();
        };
        function handleEditBudget(budget){
            (async () => {
                const budgetObj = {
                    "id":budget.id,
                    "potje":budget.potje,
                    "amount":document.getElementById('new-amount').value,
                    "account":budget.account
                };
                console.log(budgetObj)
                const response = await editPotje(budgetObj);
                if(response && response.status === 200) {
                    refresh(true)
                    setEditing(false)
                }
                else setAlert(`Error: ${response.status} editing budget`);
            })();
        };
        return(
            <Row style={{borderBottom:'1px dashed var(--bs-primary)'}}>
                <Col>{props.budget.potje}</Col>
                <Col>
                    {editing ? 
                        <>
                            <Form.Control id='new-amount' size="sm" type="text" defaultValue={props.budget.amount.toFixed(2)} autoFocus/>
                            <X color="var(--bs-danger)" size={20} className="float-r c-pointer mrgnT1" onClick={() => setEditing(false)}/>
                            <Check color="var(--bs-success)" size={20} className="c-pointer" onClick={() => handleEditBudget(props.budget)}/>
                        </>
                    :
                        <>
                            {`\u20AC${props.budget.amount}`}
                            <Trash onClick={() => handleDeleteBudget(props.budget)} className="float-r mrgnT1 c-pointer" color="var(--bs-danger)" />
                            <PencilSquare onClick={() => setEditing(true)} className='float-r mrgnR2 mrgnT1 c-pointer' />
                        </>
                    }
                </Col>
            </Row>
        );
    };

    const orderCategories = () => {
        const catArr = [];
        for(let item of props.categories){
            if(!catArr.some(c => c.category === item.category)){
                catArr.push({category:item.category,items:[item]});
            }
            else{
                const match = catArr.find(c => c.category === item.category);
                match.items.push(item);
            };
        };
        return catArr;
    };

    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Instellingen van account:
            <ButtonGroup className='mrgnL3'>
                <Button variant={props.account === 'huiskas' ? 'primary':'outline-primary'} onClick={() => props.setAccount('huiskas')}><House size={50} /></Button>
                <Button variant={props.account === 'carlie' ? 'primary':'outline-primary'} onClick={() => props.setAccount('carlie')}><GenderFemale size={50} /></Button>
                <Button variant={props.account === 'sjors' ? 'primary':'outline-primary'} onClick={() => props.setAccount('sjors')}><GenderMale size={50} /></Button>
            </ButtonGroup>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {alert && <Alert>{alert}</Alert>}
            <Row style={{borderBottom:'1px solid var(--bs-primary)'}}>
                <Col>
                    <h6>{`All Transactions (${props.transactions.length})`}</h6>
                </Col>
                <Col>
                    <h6>All Categories</h6>
                </Col>
                <Col>
                    <h6>All Budgets</h6>
                </Col>
            </Row>
            <Row>
                <Col style={{borderRight:'1px dashed var(--bs-primary)', height:'500px', overflowY:'auto', overflowX:'hidden'}}>
                    {props.transactions.map((t,index) => 
                        transactionRow(t,index)
                    )}
                </Col>
                <Col style={{borderRight:'1px dashed var(--bs-primary)',height:'500px',overflowY:'auto'}}>
                    {orderCategories().map((c,index) =>
                        <CatRow category={c} index={index} key={`category-row-${c.category}`}/>
                    )}
                    {createCategory ?
                        <>
                            <Row>
                                <Col>
                                    <Form.Control id='new-category' size="sm" type="text" placeholder="Nieuwe categorie naam" autoFocus/>
                                </Col>
                                <Col>
                                    <Form.Control id='new-search-string' size="sm" type="text" placeholder="Nieuwe zoekterm"/>
                                </Col>
                            </Row>
                            <Row style={{borderBottom:'1px dashed var(--bs-primary)'}}>
                                <Col><Check color="var(--bs-success)" size={20} className="c-pointer" onClick={() => handleAddCategory()}/></Col>
                                <Col><X color="var(--bs-danger)" size={20} className="c-pointer" onClick={() => setCreateCategory(false)}/></Col>
                            </Row>
                        </>
                    :
                        <Button variant='outline-primary mrgnT2' onClick={() => setCreateCategory(true)} style={{width:'100%'}}>Nieuwe categorie</Button>
                    }
                </Col>
                <Col style={{height:'500px', overflowY:'auto', overflowX:'hidden'}}>
                    {props.budgets.map(b => <BudgetRow key={`budget-${b.id}`} budget={b} />)}
                    {createBudget ?
                        <>
                            <Row>
                                <Col>
                                    <Form.Select id='new-budget' placeholder='Selecteer een categorie'>
                                        {orderCategories().filter(cat => !props.budgets.some(b => b.potje === cat.category)).map((c,index) => 
                                            <option key={`cat-select-${c}-option-${index}`} value={c.category}>{c.category}</option>
                                        )}
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Control id='new-amount' size="sm" type="text" placeholder="Bedrag (per maand)"/>
                                </Col>
                            </Row>
                            <Row style={{borderBottom:'1px dashed var(--bs-primary)'}}>
                                <Col><Check color="var(--bs-success)" size={20} className="c-pointer" onClick={() => handleAddBudget()}/></Col>
                                <Col><X color="var(--bs-danger)" size={20} className="c-pointer" onClick={() => setCreateBudget(false)}/></Col>
                            </Row>
                        </>
                    :
                        <Button variant='outline-primary mrgnT2' onClick={() => setCreateBudget(true)} style={{width:'100%'}}>Nieuw potje</Button>
                    }
                    </Col>
            </Row>
        </Modal.Body>
      </Modal>
    );
  }