import React , {useRef, useState} from "react";
import { Row , Col, ProgressBar, Alert, ButtonGroup , Button} from "react-bootstrap";
import { Pie , Line , getElementAtEvent } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { categoryColors , shadesGenerator } from "../../../helpers/categoryColors";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Filler,
  } from 'chart.js';

ChartJS.register(
CategoryScale,
LinearScale,
PointElement,
LineElement,
Title,
Tooltip,
Legend,
ArcElement,
ChartDataLabels,
Filler
);

export default function FinHealthTile(props){
    const [mode,setMode] = useState('month');
    const labels = ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Jun', 'Jul','Aug','Sep','Okt','Nov','Dec'];
    const totalDebit = (transactions) => {
        let total = 0;
        for(let t of transactions){
            if(t.amount < 0) total += t.amount;
        };
        return total.toFixed(2);
    };
    const totalCredit = (transactions) => {
        let total = 0;
        for(let t of transactions){
            if(t.amount > 0) total += t.amount;
        };
        return total.toFixed(2);
    };
    const balance = (transactions) => {
        let total = 0;
        for(let t of transactions){
            total += t.amount;
        };
        return total.toFixed(2);
    };
    const yearData = {
        labels,
        datasets: [
          {
            label: 'Uitgaven',
            data: labels.map((_,index) => totalDebit(props.allTransactions.filter(t => new Date(t.date_booked).getMonth() === index && new Date(t.date_booked).getFullYear() === props.year)) * -1),
            borderColor: 'rgb(173, 59, 46)',
            backgroundColor: 'rgba(173, 59, 46, 0.5)',
            tension:0.3
          },
          {
            label: 'Inkomsten',
            data: labels.map((_,index) => totalCredit(props.allTransactions.filter(t => new Date(t.date_booked).getMonth() === index && new Date(t.date_booked).getFullYear() === props.year))),
            borderColor: 'rgb(118, 173, 46)',
            backgroundColor: 'rgba(118, 173, 46, 0.5)',
            tension:0.3
          },
          {
            label: 'Balans',
            data: labels.map((_,index) => balance(props.allTransactions.filter(t => new Date(t.date_booked).getMonth() === index && new Date(t.date_booked).getFullYear() === props.year))),
            borderColor: 'rgb(0, 0, 0)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            fill:'origin',
            tension:0.3
          }
        ],
      };
    const yearDataOptions = {
        plugins:{
            datalabels: {
                formatter: function(value, context) {
                    return '';
                },
              }
        }
    };

    function BudgetBar(props){
        const spent = totalDebit(props.transactions)*-1
        const percentage = (spent*100/props.budget.amount).toFixed(0);
        const overspent = percentage > 100;
        return(
            <Alert variant={overspent ? 'danger':'primary'}>
                <Row>
                    <Col md={5}><h5 className="inline">{props.budget.potje}</h5></Col>
                    <Col md={4}>{overspent ? `\u20AC${(spent-props.budget.amount).toFixed(2)} over budget!`:`\u20AC${(props.budget.amount-spent).toFixed(2)} over`}</Col>
                    <Col><ProgressBar animated variant={overspent ? 'danger':'success'} now={percentage} label={`${percentage}%`}/></Col>
                </Row>
            </Alert>
        );
    };
    function CategoryPieChart(props){
        const pieChartRef = useRef();
        const categories = () => {
            const tempCats = [];
            for(let t of props.transactions){
                if(!tempCats.some(c => c.label === t.category) && t.amount < 0) tempCats.push({label:t.category,value:t.amount,color:categoryColors(t.category)});
                else if(t.amount < 0) {
                    const matchingCat = tempCats.find(c => c.label === t.category);
                    matchingCat.value = matchingCat.value+t.amount;
                };
            };
            return tempCats.sort(function (a, b) {
                return ('' + a.label).localeCompare(b.label);
            });
        };
        const elements = () => {
            const tempElements = [];
            const filteredTransactions = props.transactions.filter(t => t.category === props.catFilter);
            let index = 0;
            for(let t of filteredTransactions){
                tempElements.push({label:t.transaction_description.slice(0,20),value:t.amount, color:shadesGenerator(props.catFilter,filteredTransactions.length,index), date:new Date(t.date_booked).toLocaleDateString()});
                index += 1;
            };
            return tempElements;
        };
        const monthData = { 
            labels: props.catFilter ? elements().map(e => `${e.label}\n${e.date}`):categories().map(c => c.label),
            datasets: [
              {
                data: props.catFilter ? elements() : categories(),
                backgroundColor: props.catFilter ? elements().map(e => `rgba(${e.color},0.4)`) : categories().map(c => `rgba(${c.color},0.4)`),
                borderColor: props.catFilter ? elements().map(e => `rgba(${e.color},0.4)`) : categories().map(c => `rgba(${c.color},1)`),
                borderWidth: 2,
              },
            ],
        };
        const monthDataOptions = {
            plugins:{
                datalabels: {
                    formatter: function(_, context) {
                        return `${context.dataset.data[context.dataIndex].label} \n \u20AC${context.dataset.data[context.dataIndex].value.toFixed(2)}`;
                    },
                    display:'auto'
                  }
            }
        };
        function handleZoom(e){
            if(props.catFilter) props.setCatFilter('');
            else {
                const element = getElementAtEvent(pieChartRef.current,e);
                if(element.length !== 0) props.setCatFilter(categories()[getElementAtEvent(pieChartRef.current,e)[0].index].label)
            };
        };
        return(
            <Pie 
                ref={pieChartRef} 
                data={monthData} 
                options={monthDataOptions} 
                onClick={(e) => handleZoom(e)}/>
        );
    };

    return(
        <>
            <Row className="tile-wrapper">
                <Col md={12}>
                    <h4 className="inline">{props.catFilter ? props.catFilter : mode === 'month' ? 'Maandoverzicht':'Jaaroverzicht'}</h4>
                    <ButtonGroup className="float-r d-none d-md-flex">
                        <Button variant={mode === 'month' ? 'primary':'outline-primary'} onClick={() => setMode('month')}>Maand</Button>
                        <Button variant={mode === 'year' ? 'primary':'outline-primary'} onClick={() => setMode('year')}>Jaar</Button>
                    </ButtonGroup>
                </Col>
                <Col style={{minHeight:'500px'}}>
                    <Row>
                        <Col md={12} lg={6} className="d-none d-md-flex">
                            {mode === 'month' && <CategoryPieChart setCatFilter={props.setCatFilter} catFilter={props.catFilter} transactions={props.transactions} />}
                            {mode === 'year' && <Line options={yearDataOptions} data={yearData}/>}
                        </Col>
                        <Col md={6} style={{borderLeft:'1px dashed var(--bs-primary)'}}>
                            <div className="">
                                <h6>Totalen deze maand</h6>
                                <div>Afgeschreven: <span className='neg block'>{`\u20AC${totalDebit(props.transactions)}`}</span></div>
                                <div style={{borderBottom:'1px dashed var(--bs-primary)'}}>Bijgeschreven: <span className='pos block'>{`\u20AC${totalCredit(props.transactions)}`}</span></div>
                                <div>Balans: <span className={balance(props.transactions) < 0 ? 'neg':'pos'}>{`\u20AC${balance(props.transactions)}`}</span></div>
                                <h6>Totaal zonder categorie: {`\u20AC${totalDebit(props.transactions.filter(t => t.category === 'N/A'))}`}</h6>
                                <br />
                            </div>
                            <h5>Potjes</h5>
                            {props.budgets.map(b => 
                                <BudgetBar 
                                    budget={b} 
                                    key={`budget-bar-${b.id}`}
                                    transactions={props.transactions.filter(t => t.category === b.potje)}
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};