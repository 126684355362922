export function matchCategories(transactions,categories){
    console.log(transactions,categories)
    if(transactions.length === 0 || categories.length === 0) return [];
    else{
        for(let transaction of transactions){
            const category = categories.find(c => transaction.transaction_description.includes(c.search_string));
            if(category) transaction.category = category.category;
            else transaction.category = 'N/A';
        };        
        return transactions.sort((a,b) => new Date(b.date_booked) - new Date(a.date_booked));
    }
};