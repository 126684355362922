export function categoryColors(category){
    switch (category) {
        case 'Spaarpot': return '39,174,96'
        case 'Sjepkes': return '155, 89, 182'
        case 'Auto': return '52, 73, 94'
        case 'Huis': return '41, 128, 185'
        case 'GWL': return '127, 140, 141'
        case 'Beestjes': return '26, 188, 156'
        case 'Communicatie': return '241, 196, 15'
        case 'Drogist & huiswaar': return '243, 156, 18'
        case 'Drogist': return '243, 156, 18'
        case 'Financiele diensten': return '211, 84, 0'
        case 'Bijdrage': return '0, 0, 0'
        case 'Uitjes': return '245,183,177'
        case 'Gezondheid': return '24,106,59'
        case 'OV': return '183,149,11'
        case 'N/A': return '255, 0, 0'
        default: return `${Math.floor(Math.random()*255)} , ${Math.floor(Math.random()*255)},${Math.floor(Math.random()*255)}`;
    }
};
export function shadesGenerator(category,length,index){
    const initialColor = categoryColors(category).split(',');
    const shade = []
    for(let colorStrength of initialColor){
        shade.push(Math.floor((index+1)*Number(colorStrength)/length));
    };
    return shade.join(',');
};
//https://i.pinimg.com/originals/fb/ab/f6/fbabf64c1edb1340a954c320bf9f6430.png