import React , { useState }from "react";
import { Row , Col , Form, Alert } from "react-bootstrap";
import { addTransaction } from "../../../helpers/ApiHandler";
import { Gear } from "react-bootstrap-icons";
import SettingsModal from "../SettingsModal/SettingsModal";

export default function UploadCSVTile(props){
    const [alert,setAlert] = useState('');
    const [showSettings,setShowSettings] = useState(false);

    async function uploadFile(){
        const csv = document.getElementById('csvFile').files[0];
        let reader = new FileReader();
        let file_contents = (e) => {
            parseCSV(e.target.result);
        };
        let on_reader_load = (csv) => {
            return file_contents;
        };
        reader.onload = on_reader_load(csv);
        reader.readAsText(csv);
    };
    const lastTransaction = () => {
        const sorted = props.transactions.sort((a,b) => new Date(b.date_booked) - new Date(a.date_booked));
        if(sorted.length > 0) return new Date(sorted[0].date_booked);
        else return new Date(0);
    };
    function parseCSV(data){
        const lines = data.split(/[\r\n]+/g);
        const asnLayout = {'date':0,'amount':10,'amount_on_account':8,'prim_descr':3,'sec_descr':17};
        const ingLayout = {'date':0,'amount':6,'amount_on_account':9,'prim_descr':1,'sec_descr':8}
        const transactionArray = [];
        for(let line of lines){
            let transactionObj = {};
            let items = line.replaceAll('"','').split(';');
            if(props.account === 'huiskas' && line){
                transactionObj.date_booked = items[asnLayout.date].split('-').reverse().join('-');
                transactionObj.amount = parseFloat(items[asnLayout.amount].replace(',','.'));
                transactionObj.amount_on_account = parseFloat(items[asnLayout.amount_on_account].replace(',','.'));
                transactionObj.transaction_description = items[asnLayout.prim_descr] ? items[asnLayout.prim_descr] : items[asnLayout.sec_descr];
            }
            else if(props.account === 'carlie' && line && items[0] !== 'Datum'){
                transactionObj.date_booked = `${items[ingLayout.date].slice(0,4)}-${items[ingLayout.date].slice(4,6)}-${items[ingLayout.date].slice(6)}`;
                transactionObj.amount = items[5] === 'Af' ? parseFloat(items[ingLayout.amount].replace(',','.')*-1) : parseFloat(items[ingLayout.amount].replace(',','.'));
                transactionObj.amount_on_account = parseFloat(items[ingLayout.amount_on_account].replace(',','.'));
                transactionObj.transaction_description = items[ingLayout.prim_descr] ? items[ingLayout.prim_descr] : items[ingLayout.sec_descr];
            }
            transactionArray.push(transactionObj);
        };
        console.log('Found transactions:',transactionArray);

        const checkedTransactions = [];
        for(let t of transactionArray){
            if(t.date_booked && t.amount && t.amount_on_account && t.transaction_description){
                const isoTransactionDate = new Date(t.date_booked);
                if(isoTransactionDate.getTime() > lastTransaction().getTime()) checkedTransactions.push(t);
                else if(isoTransactionDate.getTime() === lastTransaction().getTime()){
                    const alreadyFound = props.transactions.find(transaction => (t.amount === transaction.amount && t.amount_on_account === transaction.amount_on_account && t.transaction_description === transaction.transaction_description));
                    if(!alreadyFound) checkedTransactions.push(t);
                };
            }
            else console.log('Error in transaction',t)
        };
        console.log('Checked & filtered transactions: ',checkedTransactions);
        (async () => {
            for(let transaction of checkedTransactions){
                transaction.account = props.account;
                const response = await addTransaction(transaction);
                if(!response || !response.status === 200) {
                    setAlert('Ooepsie, API Error! Laat sjors ff checken');
                    console.log('Upload failed: ',transaction,response);
                };
            };
            props.setUploading(true);
        })();  
    };

    
    return(
        <>
            <SettingsModal 
                categories={props.categories} 
                transactions={props.transactions.filter(t => t.account === props.account)}
                budgets={props.budgets}
                show={showSettings}
                onHide={() => setShowSettings(false)}
                refresh={props.refresh}
                account={props.account}
                setAccount={props.setAccount}
            />
            <Row className="tile-wrapper" style={{minHeight:'230px'}}>
                {alert && <Alert>{alert}</Alert>}
                <Col md={12}>
                    <h4 className="inline">Upload</h4>
                    <Gear className="float-r c-pointer d-none d-md-flex" size={30} onClick={() => setShowSettings(true)}/>
                </Col>
                <Col md={12}>
                    <Alert className='mrgnT2' variant={new Date().getTime() > (lastTransaction().getTime()+14*24*3600*1000) ? 'danger':'primary'}>Laatst bekend: {lastTransaction().toLocaleDateString()}</Alert>
                </Col>
                <Col>
                    <Form.Group controlId="csvFile" className="mb-3">
                        <Form.Control type="file" accept='.csv' onChange={() => uploadFile()}/>
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};