import React , { useState } from "react";
import { Col, Modal, Row, Button, Form, Alert } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { addCategory , editCategory } from "../../../helpers/ApiHandler";

export default function CategoryModal(props) {
    const [selectedCategory,setSelectedCategory] = useState('');
    const [alert,setAlert] = useState('');
    const [searchTerm,setSearchTerm] = useState('Geen selectie');

    if(!props.selectedTransaction.hasOwnProperty('category')) return;
    const catArray = () => {
        const arr = [];
        for(let item of props.categories) {
            if(!arr.includes(item.category)) arr.push(item.category);
        };
        return arr;
    };
    function highlightedText(text){
        const asArray = text.split(searchTerm);
        if(asArray.length !== 2) return text;
        else return (
            <>
                {asArray[0]}
                <span style={{backgroundColor:'var(--bs-success)'}}>{searchTerm}</span>
                {asArray[1]}
            </>
        );
    };
    function handleSave(){
        const catObj = {
            "search_string":searchTerm,
            "account":props.selectedTransaction.account
        };
        (async () => {
            if(selectedCategory !== 'new' && props.selectedTransaction.category !== 'N/A'){
                catObj.category = selectedCategory;
                catObj.id = props.categories.find(c => c.category === selectedCategory).id;
                const response = await editCategory(catObj);
                if(response && response.status === 200) {props.onHide();props.refresh(true)}
                else {console.log(response);setAlert('Oepsie, een API error')};
            }
            else{
                if(selectedCategory === 'new') catObj.category = document.getElementById('cat-name').value;
                else catObj.category = selectedCategory;
                const response = await addCategory(catObj);
                if(response && response.status === 200) {props.onHide();props.refresh(true)}
                else {console.log(response);setAlert('Oepsie, een API error')};
            };
        })();
    };
    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Waar hoort deze transactie bij?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {alert && <Alert>{alert}</Alert>}
            <Row>
                <Col md={4}><h6>Account</h6></Col>
                <Col>{props.selectedTransaction.account}</Col>
            </Row>
            <Row>
                <Col md={4}><h6>Datum</h6></Col>
                <Col>{new Date(props.selectedTransaction.date_booked).toLocaleDateString()}</Col>
            </Row>
            <Row>
                <Col md={4}><h6>Bedrag</h6></Col>
                <Col>{`\u20AC${props.selectedTransaction.amount}`}</Col>
            </Row>
            <Row>
                <Col md={4}><h6>Huidige categorie</h6></Col>
                <Col>{props.selectedTransaction.category}</Col>
            </Row>
            <Row>
                <Col md={4}><h6>Omschrijving</h6>Selecteer tekst!</Col>
                <Col onMouseUp={() => setSearchTerm(window.getSelection().toString())}>{highlightedText(props.selectedTransaction.transaction_description)}</Col>
            </Row>
            <Row>
                <Col>
                    {catArray().map(cat =>
                        <Button 
                            variant={selectedCategory === cat ? 'primary':'outline-primary'} 
                            onClick={() => setSelectedCategory(cat)}
                            key={`cat-${cat}`}
                            style={{height:'64px'}}
                            className="mrgnR1 mrgnT1"
                        >
                            {cat}
                        </Button>
                    )}
                    <Button 
                        variant={selectedCategory === 'new' ? 'primary':'outline-primary'}
                        onClick={() => setSelectedCategory('new')}
                        style={{height:'64px'}}
                        className="mrgnR1 mrgnT1"
                    >
                        <Plus size={50}/>
                    </Button>
                </Col>
            </Row>
            {selectedCategory === 'new' && 
                <Row className='mrgnT2'>
                    <Col>
                        <Form.Control size="lg" type="text" placeholder="Naam categorie" id='cat-name'/>
                    </Col>
                </Row>
            }
            <Row className='mrgnT2'>
                <Col md={4}><h6>Zoekterm:</h6></Col>
                <Col>{searchTerm}</Col>
            </Row>
            <Row className="mrgnT3">
                <Col>
                    <Button style={{height:'64px'}} onClick={() => handleSave()}>Opslaan</Button>
                </Col>
                <Col>
                    <Button className="float-r" variant='outline-danger' style={{height:'64px'}} onClick={() => props.onHide()}>Annuleren</Button>
                </Col>
            </Row>
        </Modal.Body>
      </Modal>
    );
  }