import React from "react";
import { Row , Col , ButtonGroup , Button } from "react-bootstrap";
import { House , GenderFemale , GenderMale } from "react-bootstrap-icons";

export default function AccountSelectTile(props){

    return(
        <>
            <Row className="tile-wrapper" style={{minHeight:'230px'}}>
                <Col md={12}>
                    <h4>Selecteer Account</h4>
                </Col>
                <Col md={12}>
                    Huidig account: {props.account}
                </Col>
                <Col className="mrgnT2">
                    <ButtonGroup>
                        <Button variant={props.account === 'huiskas' ? 'primary':'outline-primary'} onClick={() => props.setAccount('huiskas')}><House size={40} /></Button>
                        <Button variant={props.account === 'carlie' ? 'primary':'outline-primary'} onClick={() => props.setAccount('carlie')}><GenderFemale size={40} /></Button>
                        <Button variant={props.account === 'sjors' ? 'primary':'outline-primary'} onClick={() => props.setAccount('sjors')}><GenderMale size={40} /></Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </>
    );
};