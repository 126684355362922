import React from "react";
import { Row , Col , ButtonGroup , Button, DropdownButton, Dropdown } from "react-bootstrap";

export default function MonthSelectTile(props){
    const monthNames = ['Jan','Feb','Mrt','Apr','Mei','Jun','Jul','Aug','Sep','Okt','Nov','Dec'];
    const years = [2024,2025];
    return(
        <>
            <Row className="tile-wrapper" style={{minHeight:'230px'}}>
                <Row><Col className="mrgnT2">
                    <Row><Col><h4>Selecteer maand</h4></Col></Row>
                    {props.displaySize !== 'xxl' ? 
                        <ButtonGroup>
                            {years.map(y => 
                                <Button 
                                    variant={props.selectedYear === y ? 'primary':'outline-primary'} 
                                    onClick={() => props.setSelectedYear(y)}
                                    key={`year-select-${y}`}
                                    style={{height:'40px'}}
                                >
                                    {y}
                                </Button>
                            )}
                            <DropdownButton as={ButtonGroup} title={monthNames[props.selectedMonth]} id="bg-nested-dropdown">
                                {monthNames.map((m,index) => 
                                    <Dropdown.Item 
                                        variant={props.selectedMonth === index ? 'primary':'outline-primary'} 
                                        onClick={() => props.setSelectedMonth(index)}
                                        key={`month-select-${index}`}
                                        style={{height:'40px'}}
                                    >
                                        {m}
                                    </Dropdown.Item>
                                )}
                            </DropdownButton>
                        </ButtonGroup>
                    :
                    <>
                        <ButtonGroup style={{width:'15%'}} className='mrgnR2'>
                            {years.map(y => 
                                <Button 
                                    variant={props.selectedYear === y ? 'primary':'outline-primary'} 
                                    onClick={() => props.setSelectedYear(y)}
                                    key={`year-select-${y}`}
                                    style={{height:'64px'}}
                                >
                                    {y}
                                </Button>
                            )}
                        </ButtonGroup>
                        <ButtonGroup style={{width:'80%'}} vertical={props.displaySize === 'xs' || props.displaySize === 'sm'}>
                            {monthNames.map((m,index) => 
                                <Button 
                                    variant={props.selectedMonth === index ? 'primary':'outline-primary'} 
                                    onClick={() => props.setSelectedMonth(index)}
                                    key={`month-select-${index}`}
                                    style={{height:'64px'}}
                                >
                                    {m}
                                </Button>
                            )}
                        </ButtonGroup>
                    </>
                    }
                </Col></Row>
            </Row>
        </>
    );
};