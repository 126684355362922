import axios from 'axios';

//auth
export async function getToken(username,password){
    try {
        const response = await axios.post(
            'https://budget.sltd.nl/api/auth/login.php/',
            { 'un':username,'pw':password }
        );
        return response;
    } catch (error) {
        return error.response;
    }
};
export async function getAllTransactions(){
    try {
        const response = await axios.get(
            'https://budget.sltd.nl/api/index.php/budget/listAll',
            {headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}}
        );
        return response;
    } catch (error) {
        return error.response;
    }
};
export async function addTransaction(transaction){
    try {
        const response = await axios.post(
            'https://budget.sltd.nl/api/index.php/budget/add',
            transaction,
            {headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}}
        );
        return response;
    } catch (error) {
        return error.response;
    }
};
export async function getAllCategories(){
    try {
        const response = await axios.get(
            'https://budget.sltd.nl/api/index.php/category/listAll',
            {headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}}
        );
        return response;
    } catch (error) {
        return error.response;
    }
};
export async function addCategory(category){
    try {
        const response = await axios.post(
            'https://budget.sltd.nl/api/index.php/category/add',
            category,
            {headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}}
        );
        return response;
    } catch (error) {
        return error.response;
    }
};
export async function editCategory(category){
    try {
        const response = await axios.put(
            'https://budget.sltd.nl/api/index.php/category/update',
            category,
            {headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}}
        );
        return response;
    } catch (error) {
        return error.response;
    }
};
export async function deleteCategory(id){
    try {
        const response = await axios.delete(
            'https://budget.sltd.nl/api/index.php/category/delete',
            {
                headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`},
                data: {"id":String(id)}
            }
        );
        return response;
    } catch (error) {
        return error.response;
    }
};

export async function getAllPotjes(){
    try {
        const response = await axios.get(
            'https://budget.sltd.nl/api/index.php/potjes/listAll',
            {headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}}
        );
        return response;
    } catch (error) {
        return error.response;
    }
};
export async function addPotje(potje){
    try {
        const response = await axios.post(
            'https://budget.sltd.nl/api/index.php/potjes/add',
            potje,
            {headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}}
        );
        return response;
    } catch (error) {
        return error.response;
    }
};
export async function editPotje(potje){
    try {
        const response = await axios.put(
            'https://budget.sltd.nl/api/index.php/potjes/update',
            potje,
            {headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}}
        );
        return response;
    } catch (error) {
        return error.response;
    }
};
export async function deletePotje(id){
    console.log(id)
    try {
        const response = await axios.delete(
            'https://budget.sltd.nl/api/index.php/potjes/delete',
            {
                headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`},
                data: {"id":id}
            }
        );
        return response;
    } catch (error) {
        return error.response;
    }
};