import React , { useState , useEffect , useCallback } from 'react';
import './Login.css';
import { useNavigate } from "react-router-dom";
import { Container , Modal , Button , Form , InputGroup } from 'react-bootstrap';
import { Eye , EyeSlash , Key, PersonCircle } from 'react-bootstrap-icons';
import { getToken } from '../../helpers/ApiHandler';

export default function Login(props) {
  const [tryAuth, setTryAuth] = useState(false);
  const [showPassword,setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter') setTryAuth(true);
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    (async () => {
      if(tryAuth){
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
        if(!email || !password){
          setTryAuth(false);
          return;
        };
        // console.log(email,password);
        const response = await getToken(email,password);
        // console.log(response);
        if(response.status === 200){
          localStorage.setItem('accessToken', response.data.jwt);
          navigate('/dashboard');
        }
        else if(response.status === 401){
          setTryAuth(false);
        }
      };
    })();
  }, [tryAuth,navigate,props]);

  const showPasswordHandler = () => {
    setShowPassword((prev) => !prev);
  };
  return(
    <Container>
      <Modal
        backdrop="static"
        show={true}
        centered
      >
          <Modal.Header className="bg-primary">
            <Modal.Title>Please Log In</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-light">
            <Form>
              <InputGroup className="mb-3">
                <InputGroup.Text id="email-input" style={{backgroundColor:"var(--bs-light)"}}>
                  <PersonCircle />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  id="email"
                  placeholder="Username"
                  size='lg'
                  required
                  autoFocus
                  autoComplete='username'
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="password-input" style={{backgroundColor:"var(--bs-light)"}}>
                  <Key />
                </InputGroup.Text>
                <Form.Control
                  type={showPassword ? "text":"password"}
                  id="password"
                  placeholder="Password"
                  aria-label="password"
                  aria-describedby="password-input"
                  size='lg'
                  required
                  autoComplete='current-password'
                />
                <InputGroup.Text onClick={showPasswordHandler} style={{backgroundColor:"var(--bs-light)"}}>
                  {showPassword ? <EyeSlash /> : <Eye />}
                </InputGroup.Text>
              </InputGroup>
                <Button 
                  variant="white" 
                  className="enter"
                  size="lg"
                  onClick={() => setTryAuth(true)}
                >
                  <span>Log in</span>
                </Button>
            </Form>
          </Modal.Body>
      </Modal>
    </Container>
  )
};