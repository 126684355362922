import React , {useState} from "react";
import { Row , Col, Alert, Button } from "react-bootstrap";
import './AllTransactionsTile.css';
import categoryIcons from "../../../helpers/categoryIcons";
import CategoryModal from "../CategoryModal/CategoryModal";


export default function AllTransactionsTile(props){
    const [showCatModal,setShowCatModal] = useState(false);
    const [selectedTransaction,setSelectedTransaction] = useState({})

    function transactionRow(transaction,index){
        return(
            <Row key={`transaction-${index}`} className="transaction">
                <Col xs={6} md={3}>{new Date(transaction.date_booked).toLocaleDateString()}</Col>
                <Col xs={6} md={2}><span className={transaction.amount < 0 ? 'neg':'pos'}>{`\u20AC${transaction.amount.toFixed(2)}`}</span></Col>
                <Col xs={12} md={5}>{transaction.transaction_description.slice(0,20)}</Col>
                <Col md={2} className='c-pointer d-none d-md-flex' onClick={() => handleCategory(transaction)}>{categoryIcons(transaction.category,30)}</Col>
            </Row>
        );
    };
    function handleCategory(transaction){
        setSelectedTransaction(transaction);
        setShowCatModal(true);
    };
    return(
        <>
            <CategoryModal 
                show={showCatModal} 
                onHide={() =>setShowCatModal(false)} 
                selectedTransaction={selectedTransaction} 
                categories={props.categories}
                refresh={props.setUploading}
            />
            <Row className="tile-wrapper">
                <Col md={12}>
                    <h4 className="inline">{props.catFilter ? `Afschrijvingen van ${props.catFilter}`:'Alle afschrijvingen'}</h4>
                    {props.catFilter && <Button variant='outline-primary' className="float-r" onClick={() => props.setCatFilter('')}>Alle afschrijvingen</Button>}
                </Col>
                <Col className="mrgnT2" style={{overflowY:'auto', overflowX:'hidden', height:'500px'}}>
                    {props.transactions.length === 0 && <Alert>Geen transacties gevonden</Alert>}
                    {props.transactions.filter(t => !props.catFilter || props.catFilter === t.category).map((t,index) =>
                        transactionRow(t,index)
                    )}
                </Col>
            </Row>
        </>
    );
};