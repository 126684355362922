import { BrowserRouter , Route , Routes , Navigate } from "react-router-dom";
import "./App.css";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import React from "react";
import Admin from "./pages/admin/Admin";
import 'bootstrap/dist/css/bootstrap.min.css';


export default function App() {
  function CheckToken({ children }) {
    const authed = localStorage.getItem('accessToken');
    return authed ? children : <Navigate to="/login" replace />;
  };

  return(
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route path='/dashboard' element={<CheckToken><Dashboard /></CheckToken>} />
        <Route path='/admin' element={<CheckToken><Admin /></CheckToken>} />
      </Routes>
    </BrowserRouter>
  )
};

