import React , {useEffect,useState} from 'react';
import './Dashboard.css';
import { Row , Col , Container , Alert } from 'react-bootstrap';
import { getAllCategories, getAllPotjes, getAllTransactions } from '../../helpers/ApiHandler';
import UploadCSVTile from './UploadCSVTile/UploadCSVTile';
import AccountSelectTile from './AccountSelectTile/AccountSelectTile';
import FinHealthTile from './FinHealthTile/FinHealthTile';
import AllTransactionsTile from './AllTransactionsTile/AllTransactionsTile';
import { matchCategories } from '../../helpers/match';
import MonthSelectTile from './MonthSelectTile/MonthSelectTile';
import { useNavigate } from 'react-router-dom';

export default function Dashboard() {
    const [alert,setAlert] = useState('');
    const [transactions,setTransactions] = useState([]);
    const [categories,setCategories] = useState([]);
    const [budgets,setBudgets] = useState([]);
    const [account,setAccount] = useState('huiskas');
    const [uploading,setUploading] = useState(false);
    const [selectedMonth,setSelectedMonth] = useState(new Date().getMonth());
    const [selectedYear,setSelectedYear] = useState(new Date().getFullYear());
    const [refresh,setRefresh] = useState(false);
    const [catFilter,setCatFilter] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        setUploading(false);
        (async () => {
            const response2 = await getAllCategories();
            if(response2 && response2.status === 200){
                setCategories(response2.data.filter(c => c.account === account));
                setRefresh(false);
                const response = await getAllTransactions();
                if(response && response.status === 200) setTransactions(matchCategories(response.data.filter(t => t.account === account),response2.data.filter(c => c.account === account)));
                else setAlert(`Error: ${response.status} getting transactions`);
            } 
            else if(response2 && response2.status === 401) navigate('/login');
            else setAlert(`Error: ${response2.status} getting categories`);
            const response3 = await getAllPotjes();
            if(response3 && response3.status === 200) setBudgets(response3.data.filter(b => b.account === account));
            else setAlert(`Error: ${response2.status} getting budgets`);
        })();
    }, [uploading,refresh,account,navigate]);

    const filteredTransactions = () => {
        return transactions.filter(t => 
            new Date(t.date_booked).getMonth() === selectedMonth && 
            new Date(t.date_booked).getFullYear() === selectedYear
            // t.account === account
        );
    };
    const displaySize = () => {
        const width = window.screen.width;
        if(width < 576) return 'xs';
        else if (width >= 576 && width < 768) return 'sm';
        else if (width >= 768 && width < 992) return 'md';
        else if (width >= 992 && width < 1200) return 'lg';
        else if (width >= 1200 && width < 1400) return 'xl';
        else return 'xxl';
    }
    displaySize()
    // const filteredBudgets = budgets.filter(b => b.account === account);
    // const filteredCategories = categories.filter(c => c.account === account);
    // console.log(budgets,transactions,categories)
    return(
        <Container fluid>
            {alert && <Alert>{alert}</Alert>}
            <Row>
                <Col xs={12} md={6} xl={4} xxl={3}>
                    <AccountSelectTile account={account} setAccount={setAccount}/>
                </Col>
                <Col xs={12} md={6} xl={4} xxl={6}>
                    <MonthSelectTile 
                        transactions={transactions} 
                        selectedMonth={selectedMonth} 
                        setSelectedMonth={setSelectedMonth} 
                        selectedYear={selectedYear} 
                        setSelectedYear={setSelectedYear}
                        displaySize={displaySize()}
                    />
                </Col>
                <Col xs={12} xl={4} xxl={3}>
                    <UploadCSVTile 
                        transactions={transactions.filter(t => t.account === account)} 
                        setUploading={setUploading} 
                        account={account}
                        setAccount={setAccount}
                        categories={categories}
                        refresh={setRefresh}
                        budgets={budgets}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} xxl={4}>
                    <AllTransactionsTile 
                        transactions={filteredTransactions()}
                        categories={categories}
                        setUploading={setUploading}
                        catFilter={catFilter}
                        setCatFilter={setCatFilter}
                    />
                </Col>
                <Col xs={12} xxl={8}>
                    <FinHealthTile 
                        transactions={filteredTransactions()}
                        budgets={budgets}
                        categories={categories}
                        allTransactions={transactions}
                        year={selectedYear}
                        catFilter={catFilter}
                        setCatFilter={setCatFilter}
                    />
                </Col>
            </Row>
        </Container>
    )
};